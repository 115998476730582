::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  width: 8px;
  background-color: silver;
}

::-webkit-scrollbar-thumb:hover {
  background-color: gray;
}

html,
body {
  scroll-behavior: smooth;
  /* background-color: black; */
  margin: 0;
  padding: 0;
  width: 100vw;
  overflow-y: auto;
  overflow-x: hidden;
}

@font-face {
  font-family: "Agustina Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Agustina Regular"),
    url("./assets/fonts/Agustina.woff") format("woff");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
