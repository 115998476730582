.loader-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: white;
}

.loader-screen svg {
  width: 58px;
  height: 58px;
}

.loader-screen svg path:nth-child(1) {
  animation: loader_scale_anim 1s infinite;
}

.loader-screen svg path:nth-child(2) {
  transform: scale(0);
  animation: loader_scale_anim 1s 0.5s infinite;
}

@keyframes loader_scale_anim {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
