.education-section {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: white;
  overflow: hidden;
  padding-bottom: 60px;
}

.education-section h1 {
  font-family: Arial, serif;
  font-size: 8vmin;
  margin: 48px 32px 20px 0;
  text-align: right;
  color: rgba(0, 0, 0, 0.1);
}

.education-shape-container {
  position: relative;
}

.education-shape-container span:nth-child(1) {
  position: absolute;
  top: -250px;
  left: -250px;
  width: 800px;
  height: 800px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.07);
}

.education-shape-container span:nth-child(2) {
  position: absolute;
  top: -150px;
  left: -150px;
  width: 500px;
  height: 500px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.07);
}

.education-description {
  font-family: "Arial Black", serif;
  font-size: 3vmin;
  padding: 24px;
  background: linear-gradient(
    315deg,
    rgb(66, 211, 146) 25%,
    rgb(100, 126, 255)
  );
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}
