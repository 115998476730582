.footer {
  font-family: Candara, serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 72px;
  background: rgb(26, 45, 62);
  color: white;
}

.footer p {
  font-size: 20px;
  padding-left: 32px;
  padding-right: 32px;
}

.footer div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 32px;
}

.footer div a {
  margin: 8px;
}
