.contact-input {
  font-size: 16px;
  position: relative;
  width: 95%;
  outline: none;
  border: 2px solid #50afff;
  border-radius: 0;
  padding: 16px;
  margin: 12px 0;
  background: transparent;
  color: white;
  transition: all 0.5s;
}

.contact-input::placeholder {
  color: silver;
}

.contact-input:focus {
  background: rgba(255, 255, 255, 0.25);
  border: 2px solid #42d392;
}

.contact-input::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  height: 100px;
  background-color: red;
  z-index: 99;
}

.contact-textarea {
  font-size: 16px;
  position: relative;
  width: 95%;
  min-height: 300px;
  margin: 12px 0 24px 0;
  padding: 16px;
  border: 2px solid #50afff;
  outline: none;
  border-radius: 0;
  background: transparent;
  color: white;
  transition: all 0.5s;
}

.contact-textarea::placeholder {
  font-family: Arial, serif;
  color: silver;
}

.contact-textarea:focus {
  background: rgba(255, 255, 255, 0.25);
  border: 2px solid #42d392;
}

.contact-image {
  background-image: url(../assets/images/contact-parallax.jpg);
  background-size: cover;
  background-position: center center;
  /* min-height: 480px; */
  padding: 32px;
  background-color: rgba(0, 0, 0, 0.6);
  background-blend-mode: darken;
}

.contact-info {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-info-title {
  font-family: Arial, serif;
  font-size: 8vmin;
  width: 100%;
  height: 16vmin;
  margin: 0;
  color: white;
  text-align: center;
}

.contact-info-icons {
  width: 100%;
  max-width: fit-content;
  display: grid;
  justify-content: center;
  gap: 16px;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  margin-left: auto;
  margin-right: auto;
}

.contact-info-icons a {
  text-align: center;
}

.contact-info-icons a svg {
  width: 6vmin;
  height: 6vmin;
}

.contact-form {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}
